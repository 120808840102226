
.text-black{
  color:black;
}

.link-black, .link-black:hover {
  color:black;
  cursor: pointer;
}

.link-black:hover {
  text-decoration: underline;
}


.min-content {
  height: min-content;
}

.btn, .modal-content, .card {
  border-radius: 0;
}


.nav-offset{
  margin-top: 57px;
}

.wrapper {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

#root {
  height: 100vh; /* Fallback */
  height: 100svh;
}

select.form-control.is-valid{
  background-image: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 576px) {
  nav {
    padding-left: 5%;
    padding-right: 5%;
  }

  /* h1{
    font-size: 250%;
  } */

  .sm-mb{
    margin-bottom: 3%;
  }

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) {
  nav {
    padding-left: 5%;
    padding-right: 5%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  nav {
    padding-left: 10%;
    padding-right: 10%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  nav {
    padding-left: 10%;
    padding-right: 10%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  nav {
    padding-left: 20%;
    padding-right: 20%;
  }
}
